import React from "react"

import SEO from "../../../components/seo"
import Layout from "../../../components/layout"
import CustomNavTitle from "../../../components/CustomNavTitle"
import DefaultLayoutSidebar from "../../../components/DefaultLayoutSidebar"

import illustration2x from "../../../images/illustrations/illustration-cryo@2x.png"

import { theme } from "../../../styles/theme"

const HodgkinLymphoma = ({ location }) => {
  return (
    <Layout>
      <SEO title="Specific Requirements - Hodgkin Lymphoma" />

      <CustomNavTitle
        title="Specific Requirements"
        destination="/requirements/irradiation/"
        location={location}
      />

      <DefaultLayoutSidebar>
        <section className="theme max-w-content">
          <h3 css={[theme.components.title, theme.components.titleRed]}>
            Hodgkin lymphoma
          </h3>

          <h4
            className="mt-gap-2"
            css={[theme.components.title, theme.components.titleSmall]}
          >
            Indication for irradiated components
          </h4>
          <p>
            Patients diagnosed with Hodgkin lymphoma at any stage of the
            disease process.
          </p>

          <h4
            className="mt-gap-2"
            css={[theme.components.title, theme.components.titleSmall]}
          >
            Duration of requirement
          </h4>
          <p>For life.</p>
        </section>
        <aside>
          <img src={illustration2x} alt="Illustration" />
        </aside>
      </DefaultLayoutSidebar>
    </Layout>
  )
}

export default HodgkinLymphoma
